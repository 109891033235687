<template>
  <div id="Header">
    <!--logo-->
    <img class="logo" src="@/view/images/logo.png" @click="TOPortal" alt=""/>
  </div>
</template>

<script>
export default {
  name: 'HeaderPart',
  data() {
    return {
      VUE_APP_HOME: process.env.VUE_APP_HOME, // portal主站地址
    };
  },
  methods: {
    // 跳转至portal主页
    TOPortal() {
      window.open(this.VUE_APP_HOME, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  // logo
  .logo {
    display: block;
    cursor: pointer;
  }
</style>
