<template>
  <div class="Home">
    <on-header></on-header>
    <router-view></router-view>
    <on-footer></on-footer>
  </div>
</template>

<script>
import OnHeader from '../components/Header';
import OnFooter from '../components/Footer';

export default {
  name: 'HomePage',
  components: {
    OnHeader,
    OnFooter,
  },
};
</script>

<style lang="scss" scoped>
.Home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 1280px;
  min-height: 800px;
  padding: 50px 50px 30px;
  background: #eef1f6;
  background-image: url(../view/images/login_bg.png);
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  transition: background .3s;
}
@media screen and (max-width:800px) {
  .Home {
    min-width: 320px !important;
    padding: 60px 30px 10px;
    min-height: 100%;
  }
}
</style>
