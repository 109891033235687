<template>
  <div id="Footer">
    <div class="end">
      Copyright&copy; &nbsp;2019-{{ DataYears }} &nbsp;印工社（青岛）数字科技有限公司 All
      Rights Reserved&nbsp; | &nbsp;鲁ICP备12040237号-2&nbsp; <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37021402002207\" target="_banck">鲁公网安备 37021402002207号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPart',
  computed: {
    DataYears() {
      const date = new Date();
      const years = date.getFullYear();

      return years;
    },
  },
};
</script>

<style lang="scss" scoped>
.end {
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  color: #231815;
}
.end a {
  color: #231815;
  text-decoration: none
}
@media screen and (max-width:800px) {
  .end {
    display: none;
  }
}
</style>
